<template>
  <div>
    <a-spin :spinning="loading">
      <a-descriptions
        :column="3"
        class="iotplt-descriptions iotplt-descriptions-max-10"
      >
        <a-descriptions-item label="订单批次">
          {{ agentActiveOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="操作人">
          {{ agentActiveOrder.operator_name }}
        </a-descriptions-item>

        <a-descriptions-item label="订单时间">
          {{ agentActiveOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="卡号数量">
          {{ agentActiveOrder.cards_count | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="订单金额(元)">
          {{ agentActiveOrder.total_fee | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="佣金金额(元)">
          {{ agentActiveOrder.commission_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>

    <search-sim-card-active-order @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loadingSimCardActiveOrders"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <span slot="product_price_title">
        <a-tooltip>
          <template slot="title">
            这里显示的是套餐周期单价，精确到两位小数
          </template>
          套餐价格(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchSimCardActiveOrders"
    />
  </div>
</template>

<script>
import { findAgentActiveOrder, findSimCardActiveOrders, exportSimCardActiveOrders } from '@/api/agent_active_order'
import SearchSimCardActiveOrder from '@/views/agent_monthly_bills_commission/active/show/Search'
import Pagination from '@/components/Pagination'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'ShowAgentActiveOrder',
  components: {
    Pagination,
    SearchSimCardActiveOrder
  },
  data() {
    return {
      agentActiveOrder: {},
      loading: true,
      loadingSimCardActiveOrders: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      },
      data: []
    }
  },
  computed: {
    agentType() {
      return this.$store.getters.userAgentType
    },

    columns() {
      var columnArr = [
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '客户套餐',
          width: 220,
          dataIndex: 'agents_product_name'
        },
        {
          slots: { title: 'product_price_title' },
          dataIndex: 'product_price',
          customRender: formatCurrency
        },
        {
          title: '激活金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        },
        {
          title: '佣金金额(元)',
          dataIndex: 'commission_fee',
          customRender: formatCurrency
        }
      ]

      return columnArr
    },

    agentActiveOrderId() {
      return parseInt(this.$route.params.active_order_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
    this.fetchSimCardActiveOrders()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchSimCardActiveOrders()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchSimCardActiveOrders()
    },

    fetchData() {
      this.loading = true
      findAgentActiveOrder(this.agentActiveOrderId).then((res) => {
        if (res.code === 0) {
          this.agentActiveOrder = res.data

          this.$store.dispatch('ConcatDynamicBreadCrumbs', [
            { redirect: `/monthly_bills_commission`, meta: { title: '月订单' }},
            { redirect: `/monthly_bills_commission/${this.billId}`, meta: { title: this.agentActiveOrder.month }},
            { redirect: ``, meta: { title: this.agentActiveOrder.no }}
          ]
          ).then(() => {})
        }
        this.loading = false
      })
    },

    fetchSimCardActiveOrders() {
      this.loadingSimCardActiveOrders = true
      findSimCardActiveOrders(this.agentActiveOrderId, Object.assign({},
        this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loadingSimCardActiveOrders = false
      })
    },

    exportData() {
      exportSimCardActiveOrders(this.agentActiveOrderId, this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

